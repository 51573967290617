<template>
    <h1>健康フォーラム参加者一覧</h1>
    <div id="customer_table" ref="customer_table"></div>

    <!-- <table>
            <thead>
                <th>姓</th>
                <th>名</th>
                <th>ふりがな</th>
                <th>オンライン視聴</th>
                <td>来場日時</td>
            </thead>
            <tbody>
                <tr v-for="d in datas" :key="d">
                    <td>{{d["last_name"]}}</td>
                    <td>{{d["first_name"]}}</td>
                    <td>{{d["furigana"]}}</td>
                    <td><input type="checkbox" v-model="d['online']" value="1" onclick="return false"></td>
                    <td v-if="d['visited_time'] != ''">{{d["visited_time"]}}</td>
                </tr>
            </tbody>
        </table> -->
    <template v-if="loaded == true">
        <div style="margin-top: 5px;">
            <button @click="clear_sort" class="uk-button uk-button-secondary uk-button-small">clearSort</button>
        </div>
    </template>
    <template v-if="login_missed==true">
        <label for="refresh" uk-icon="icon: refresh; ratio: 2;">
            <button id="refresh" @click="init()" style="display: none;">再読み込み</button>
        </label>
    </template>

    <div class="modal" v-if="isOpen">
        <div class="modal__box">
            <span class="modal__message">検索：<input type="text" id="input_word" v-model="search_word"
                    v-on:input="data_search"></span>
            <div class="modal__action">

                <table v-if="serach_flg == true">
                    <thead>
                        <th>姓</th>
                        <th>名</th>
                        <th>ふりがな</th>
                        <th>オンライン視聴</th>
                        <th>来場日時</th>
                    </thead>
                    <tbody>
                        <tr v-for="e,index in search_datas" :key="e">
                            <td>{{e["last_name"]}}</td>
                            <td>{{e["first_name"]}}</td>
                            <td>{{e["furigana"]}}</td>
                            <td><input type="checkbox" v-model="e['online']" value="1" onclick="return false"></td>
                            <td v-if="e['visited_time'] != null ">{{e["visited_time"]}}</td>
                            <td v-else><button @click="visit(index)">来場</button></td>
                        </tr>
                    </tbody>
                </table>

                <!-- <div class="modal__btn modal__btn--success" @click="">OK</div> -->
            </div>
            <div class="modal__btn" @click="close" margin-left="45%">閉じる</div>
        </div>
    </div>
</template>

<script type="text/javascript">
    /* eslint no-unused-vars: 0 */
    // import { reactive } from 'vue';
    import { DateTime } from "luxon"
    import dayjs from "dayjs"
    import "tabulator-tables/dist/css/tabulator_midnight.min.css"
    import { TabulatorFull as Tabulator } from 'tabulator-tables';
    import firebase from 'firebase/app'
    import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
    import swal2 from "sweetalert2"
    import { QrStream } from 'vue3-qr-reader/dist/vue3-qr-reader.common'
    import { initializeApp } from "firebase/app";
    // import { getAnalytics } from "firebase/analytics";
    import { getFirestore } from "firebase/firestore";
    import { doc, getDoc, collection, addDoc, query, where, updateDoc, getDocs, getDocFromCache } from "firebase/firestore";
    // import { db } from "./firebase";
    // import { enablePersistence } from "firebase/firestore";
    import { enableMultiTabIndexedDbPersistence } from "firebase/firestore";
    import { CACHE_SIZE_UNLIMITED } from "firebase/firestore";
    import uikit from 'uikit';
    import "uikit/dist/css/uikit.min.css"
    import { setDoc } from "firebase/firestore";
    import { orderBy, startAt, endAt } from "firebase/firestore";
    // import "sheetjs"


    export default {
        comments: {
        },
        data() {
            return {
                firebaseConfig: {},
                app: "",
                analytics: "",
                db: "",
                result: '',
                error: '',
                selected: "",
                options: "",
                loaded: false,
                pre_qr: "",
                data: "",
                datas: [],
                flg: true,
                collection_name: "participant",
                search_word: "",
                search_datas: [],
                serach_flg: false,
                email: "",
                password: "",
                login_missed: false,
                autoWidth: true,
                cols: ["姓", "名", "ふりがな", "オンライン視聴", "来場日時", "入場"],
                rows: ["", "", "", "", "", ""],
                // Boolean or pagination settings object
                pagination: true,

                // Boolean or search settings object
                search: true,

                // Boolean or sort settings object
                sort: true,

                // String with name of theme or 'none' to disable
                theme: 'mermaid',

                // String with css width value
                width: '100%',
                myTable: ""
            }
        },
        computed: {
            // 追加
            isOpen() {
                return this.$store.state.modal.isOpen
            },
        },
        mounted() {
            this.init()
        },
        created() {
            window.DateTime = DateTime
        },
        methods: {
            clear_sort() {
                this.myTable.clearSort();
            },
            async init() {
                swal2.fire({
                    title: "メールアドレスとパスワードを入力してください",
                    confirmButtonText: 'Login',
                    backdrop: true,
                    allowOutsideClick: false,
                    html:
                        '<input id="input_email" type="email"  placeholder="Enter your email address" class="swal2-input">' +
                        '<input id="input_password" type="password" placeholder="Enter your password" class="swal2-input">',
                    didOpen: () => {
                        document.getElementById("input_email").value = sessionStorage.getItem("email_ad");
                        document.getElementById("input_password").value = sessionStorage.getItem("password_ad")
                    },
                    preConfirm: () => {
                        this.email = document.getElementById("input_email").value;
                        this.password = document.getElementById("input_password").value;
                        sessionStorage.setItem("email_ad", this.email)
                        sessionStorage.setItem("password_ad", this.password)
                        this.login_missed = false;
                    }

                }).then((result) => {
                    console.log(result)
                    if (result.isConfirmed) {
                        swal2.fire({
                            icon: 'info',
                            title: '読み込み中',
                            backdrop: true,
                            allowOutsideClick: false
                        });
                        swal2.showLoading();
                        this.firebaseConfig = {
                            apiKey: "AIzaSyDuWZ6n7Z9QVvxRJWLVDwZuA8C9UAgXt-Y",
                            authDomain: "non-contact-system.firebaseapp.com",
                            projectId: "non-contact-system",
                            storageBucket: "non-contact-system.appspot.com",
                            messagingSenderId: "988143458584",
                            appId: "1:988143458584:web:99cb51c9b5a2c12102266d",
                            measurementId: "G-QF7RYFCC0J"
                        }

                        // firebase.firestore()

                        this.app = initializeApp(this.firebaseConfig)
                        const auth = getAuth();
                        signInWithEmailAndPassword(auth, this.email, this.password)
                            .then((userCredential) => {
                                // Signed in
                                const user = userCredential.user;
                                console.log("user:" + user)
                                // this.analytics = getAnalytics(this.app)
                                this.db = getFirestore(this.app)
                                enableMultiTabIndexedDbPersistence(this.db).then((result) => {
                                })
                                    .catch((err) => {
                                        console.log("bb" + err)
                                        if (err.code == 'failed-precondition') {
                                            // Multiple tabs open, persistence can only be enabled
                                            // in one tab at a a time.
                                            // ...
                                        } else if (err.code == 'unimplemented') {
                                            // The current browser does not support all of the
                                            // features required to enable persistence
                                            // ...
                                        }
                                    });
                                // enableIndexedDbPersistence(this.db,{synchronizeTabs: true});
                                // this.db
                                console.log(this.db)
                                this.options = [
                                    { text: "nothing (default)", value: undefined },
                                    { text: "outline", value: this.paintOutline },
                                    { text: "centered text", value: this.paintCenterText },
                                    { text: "bounding box", value: this.paintBoundingBox },
                                ]
                                this.selected = this.options[1]
                                // document.addEventListener('touchstart', () => {
                                //     this.soundSilent()
                                // })
                                // ...

                                this.get_data().then(() => {
                                    this.loaded = true
                                    swal2.close()
                                    swal2.hideLoading();
                                    this.login_missed = false;
                                }).catch((error) => {
                                    console.log(error)
                                    this.login_missed = true;
                                })

                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                console.log("cc" + error)
                                this.get_data().then(() => {
                                    this.loaded = true
                                    swal2.close()
                                    swal2.hideLoading();
                                    this.login_missed = false;
                                }).catch((error) => {
                                    console.log(error)
                                    this.login_missed = true;
                                })


                            });
                    } else {
                        this.login_missed = true;
                    }

                })
            },
            open() {
                console.log("push button")
                const payload = {
                    isOpen: true,
                    message: '変更しますがよろしいですか？',
                }
                this.$store.dispatch('actionModalOpen', payload)
                console.log(this.$store.state.modal)
            },
            close() {
                const payload = {
                    isOpen: false,
                    message: '',
                }
                this.$store.dispatch('actionModalClose', payload)
                this.search_word = ""
                this.search_datas = []
                this.serach_flg = false
            },
            download_xlsx(){
                this.myTable.download("xlsx", "data.xlsx", { sheetName: "My Data" });
            },
            async get_data() {
                this.datas = []
                let tmp = ""
                this.rows = []

                const querySnapshot = await getDocs(collection(this.db, this.collection_name));
                let num = 0;
                querySnapshot.forEach((doc) => {
                    // console.log(doc.id)
                    tmp = doc.data()
                    tmp.id = num
                    // tmp.visited_time = new Date(tmp.visited_time)
                    this.datas.push(doc.id)
                    this.rows.push(tmp)
                    num += 1;
                })
                // console.log(`${doc.id} => ${doc.data()}`);
                console.log(this.rows)
                if (this.myTable == "") {
                    this.myTable = new Tabulator(this.$refs.customer_table, {
                        // height: 205, // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
                        data: this.rows, //assign data to table
                        pagination: "local",
                        paginationSize: 25,
                        layout: "fitColumns", //fit columns to width of table (optional)
                        columns: [ //Define Table Columns
                            { title: "姓", field: "last_name" },
                            { title: "名", field: "first_name", hozAlign: "left" },
                            { title: "ふりがな", field: "furigana", headerFilter: "input", headerFilterPlaceholder: "ふりがな検索", headerFilterLiveFilterDelay: 600 },
                            { title: "メールアドレス", field: "mail_address", headerFilter: "input", headerFilterPlaceholder: "メールアドレス検索", headerFilterLiveFilterDelay: 600 },
                            { title: "オンライン視聴", field: "online", hozAlign: "center", formatter: "tickCross", headerFilter: "tickCross", headerFilterParams: { "tristate": true }, headerFilterEmptyCheck: function (value) { return value === null } },
                            { title: "来場日時", field: "visited_time", hozAlign: "center", headerSort: false },
                        ],
                    });
                    this.myTable.on("rowClick", (e, row) => {
                        let rowData = row.getData()
                        swal2.fire({
                            icon: "question",
                            title: "",
                            html: rowData.last_name + " " + rowData.first_name + " 様の入場処理を行いますか？",
                            confirmButtonText: 'はい',
                            showCancelButton: true,
                            cancelButtonText: "いいえ"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (rowData.online == true) {
                                    swal2.fire({
                                        icon: "error",
                                        title: "オンライン視聴のお客様です"
                                    })
                                } else {
                                    if (rowData.visited_time == null) {
                                        this.visit(rowData.id).then((result) => {
                                            swal2.fire({
                                                icon: "success",
                                                title: "入場処理が完了しました"
                                            })
                                        })
                                    } else {
                                        swal2.fire({
                                            icon: "warning",
                                            title: "入場手続き済みです"
                                        })
                                    }
                                }
                            } else {
                                swal2.fire({
                                    icon: "warning",
                                    title: "キャンセルしました"
                                })
                            }
                        })
                    })
                    this.loaded = true
                } else {
                    this.myTable.updateData(this.rows)
                }
                // console.log(this.myTable)


            },
            async data_search() {
                this.search_datas = {}
                const word = document.getElementById("input_word").value
                if (word == "") {
                    return
                }
                console.log("検索: " + word)
                const keyword = word;
                const searchRef = collection(this.db, this.collection_name)
                const q = query(searchRef, orderBy("furigana"), startAt(keyword), endAt(keyword + '\uf8ff'));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    // console.log(`${doc.id} => ${doc.data()}`);
                    // doc.data() is never undefined for query doc snapshots
                    this.search_datas[doc.id] = doc.data()
                });
                this.serach_flg = true
            },
            async visit(index) {
                let uuid = this.datas[index]
                // let now = new Date()
                // let year = now.getFullYear();
                // let month = (now.getMonth() + 1).toString().padStart(2, "0");
                // let date = now.getDate().toString().padStart(2, "0");
                // let h = now.getHours().toString().padStart(2, "0");
                // let m = now.getMinutes().toString().padStart(2, "0");
                // let s = now.getSeconds().toString().padStart(2, "0");
                const docRef = doc(this.db, this.collection_name, uuid);
                await updateDoc(docRef, {
                    // visited_time: year + "/" + month + "/" + date + " " + h + ":" + m + ":" + s
                    visited_time: dayjs().format("YYYY/MM/DD HH:mm:ss")
                }).then((result) => {
                    console.log("success:" + result)
                    this.get_data().then(() => {
                        // this.loaded = true
                        // this.login_missed = false;
                        // swal2.close()
                        // swal2.hideLoading();
                    })
                }).catch((error) => {
                    console.log("error:" + error)
                });

            }
        },
    }
</script>

<style scoped>
    #customer_table {
        background-color: #ccc;
        border: 1px solid #333;
        border-radius: 10px;
    }

    /*Theme the header*/
    #customer_table .tabulator-header {
        background-color: #333;
        color: #fff;
    }

    /*Allow column header names to wrap lines*/
    #customer_table .tabulator-header .tabulator-col,
    #customer_table .tabulator-header .tabulator-col-row-handle {
        white-space: normal;
    }

    /*Color the table rows*/
    #customer_table .tabulator-tableHolder .tabulator-table .tabulator-row {
        color: #fff;
        background-color: #666;
    }

    /*Color even rows*/
    #customer_table .tabulator-tableHolder .tabulator-table .tabulator-row:nth-child(even) {
        background-color: #444;
    }

    /* * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    td {
        margin-left: 10px;
    }

    .modal {
        position: fixed;
        top: 10%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }

    .modal__box {
        overflow: scroll;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 1000px;
        height: 500px;
        padding: 32px;
        background-color: #fff;
        border-radius: 4px;
        transform: translate(-50%, -50%);
    }

    .modal__message {
        font-size: 24px;
    }

    .modal__action {
        display: flex;
        justify-content: flex-end;
    }

    .modal__btn {
        width: 130px;
        margin-left: 16px;
        padding: 8px;
        line-height: 1.5;
        font-weight: bold;
        border: 1px solid rgb(196, 196, 196);
        cursor: pointer;
    }

    .modal__btn--success {
        color: #fff;
        border: none;
        background-color: rgb(60, 140, 245);
    } */
</style>